<template>
    <div>
        <div class="mainList">

            <div class="mainList_content">
                <div class="mainList_content_operation">

                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-plus" v-if="permission(['ROLE_ADD'])" @click="add">新增</el-button>
                        <el-button type="danger" icon="el-icon-delete" v-if="permission(['ROLE_DEL'])" size="small" @click="del()">批量删除</el-button>
                    </div>
                    <div class="mainList_content_operation-c">
                        <div class="item">
                            <div class="label">角色名称</div>
                            <el-input placeholder="请输入" size="small" clearable v-model="parameter.condition.name" />
                        </div>
                        <el-button class="item" type="primary" size="small" icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                    </div>

                    <div class="mainList_content_operation-r">
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                        <el-popover class="item" placement="bottom" v-model="tableSettingVisible" popper-class="tableColumnSettingPopover">
                            <TableColumnSetting v-model="tableColumnData"></TableColumnSetting>
                            <div slot="reference" class="item icon iconfont iconliebiaoshezhi"></div>
                        </el-popover>
                    </div>



                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" :stripe="elTable.stripe" :border="elTable.border" :data="tableData" tooltip-effect="dark" height="string" @sort-change="sortChange" v-loading="loading" header-cell-class-name="meterHeader">

                        <el-table-column type="selection" align="center" width="55" fixed="left"></el-table-column>
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>

                        <el-table-column v-for="n in tableColumnData" v-if="n.isShow" :fixed="n.fixed" align="center" :prop="n.prop" :label="n.label" :sortable="n.sortable">
                            <template slot-scope="scope">
                                <template v-if="n.filter">
                                    {{  n.filter(scope.row[n.prop])  }}
                                </template>
                                <template v-else>
                                    {{  scope.row[n.prop]  }}
                                </template>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" fixed="right"  align="center" width="200"  v-if="permission(['ROLE_EDIT','ROLE_DEL','ROLE_AUTHORIZATION'])">
                            <template slot-scope="scope">

                                <el-button type="text" class="edit" @click="edit(scope.row)" v-if="permission(['ROLE_EDIT'])">编辑</el-button>
                                <el-button type="text" @click="roleAuthorization(scope.row)" v-if="permission(['ROLE_AUTHORIZATION'])">授权</el-button>
                                <el-button type="text" class="del" @click="del(scope)" v-if="permission(['ROLE_DEL'])">删除</el-button>

                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @current-change="pageChangeHandler"
                        @size-change="handleSizeChange"
                        :current-page="parameter.pageNumber"
                        :page-size="parameter.pageSize"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Edit :refresh="refresh" :isShow.sync="showEditDialog" :options="options"></Edit>
        <RoleAuthorization :refresh="refresh" :isShow.sync="showRoleAuthorization" :options="options"></RoleAuthorization>


    </div>
</template>
<script>
import mixinsList from '@/mixins/list'
export default {
    components: {
        Edit: () => import("./Edit.vue"),
        RoleAuthorization: () => import("./RoleAuthorization.vue"),
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                findPage:this.$api['admin/role'].findPage,
                del:this.$api['admin/role'].del,
            },
            showRoleAuthorization:false,
            tableColumnData:[
                {prop:'name',label:'角色名称',isShow:true,sortable:true,fixed:false,},
                {prop:'isSystem',label:'是否系统管理员',isShow:true,sortable:true,fixed:false,filter:function (v) {
                    return v == 'Y'?'是':'否';
                }},
                // {prop:'description',label:'角色描述',isShow:true,sortable:true,fixed:false,},
            ]
        };
    },
    created() {
        this.getData();
    },
    // activated () {
    //     this.$nextTick(() => {
    //         console.log(this.tableData)
    //         this.$refs.multipleTable.doLayout();
    //     });
    // },
    methods: {
        refresh(){
            this.showEditDialog = false;
            this.showRoleAuthorization = false;
            this.getData();
        },
        add() {
            this.options = {
                isSystem:'N'
            };
            this.showEditDialog = true;
        },
        roleAuthorization(row) {
            this.options = row;
            this.showRoleAuthorization = true;
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
